<template>

    <tr>
        <td v-for="(field, index) in fields" v-bind:class="{ 'hidden' : (index == 0)}">
            <component
                    v-bind:is="field.type"
                    v-on:on-change="componentUpdated(index, $event)"
                    :initial-value="rowData[field.field]"
                    :extra-options="field.options"
            ></component>
        </td>
        <td>
            <span class="btn btn-outline-danger btn-sm" v-on:click="$emit('delete-row', index)">
                <i class="fa fa-times"></i>
            </span>
        </td>
    </tr>

</template>
<script>
    import {InvisionFieldDefaultSettings} from '../../common/invisionFieldFactory'

    import _ from 'lodash'

    export default {
        name: 'list-row',
        props: ['index', 'rowData', 'rowSettings'],
        mounted () {
            this.rawData = this.transformData();
        },
        data(){
            return {
                rawData : []
            }
        },
        components: InvisionFieldDefaultSettings.getAllComponents(),
        computed :{
            fieldValues () {
                this.transformData()
            },
            fields () {
                return this.transformSettings(this.$props.rowSettings)
            },
        },
        methods: {
            componentUpdated(index, event) {
                this.rawData[index] = event;
                this.$emit('row-updated', this.rawData)
            },
            transformData() {
                let data = [];
                let fields = this.transformSettings(this.$props.rowSettings);

                _.each(fields, (field) => {
                    data.push(this.rowData[field.field])
                });
                return data;
            },
            transformSettings (settings) {
                let types = InvisionFieldDefaultSettings.getMap();
                return settings.map( (item, index) => {
                    let rObj = {};

                    rObj.index = index;
                    rObj.field = item.field;
                    rObj.type = types[item.type];
                    rObj.options = item.options;

                    return rObj;
                })
            }
        }
    }
</script>
