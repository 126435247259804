import moment from "moment";

export const dateRange = (startDate, endDate) => {
    const dates = [];

    for (const d = moment(startDate); d.isSameOrBefore(moment(endDate)); d.add(1, 'days')) {
        dates.push(d.toDate());
    }

    return dates;
}

export const isSameDate = (date1, date2) => moment(date1).isSame(moment(date2), 'date');