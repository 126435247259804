<template>
    <div>
        <div class="form-group">
            <label v-for="(day, index) in days" class="mr-1">
                <input type="checkbox" :value="index" v-model="checkValue" @change="$emit('on-change', dayString)">{{day}}
            </label>
        </div>
    </div>
</template>
<script>
    export default {
        name : 'invision-day-picker',
        props: {
            initialValue : {
                default: '',
                type: String
            },
            extraOptions : {}
        },
        data () {
            return {
                days : this.dayArray(),
                checkValue : this.splitString(this.$props.initialValue)
            }
        },
        computed : {
            dayString (){
                return this.checkValue.toString();
            }
        },
        methods : {
            dayArray() {
                let days = ['Su','Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
                return days
            },
            splitString (string) {
                if (string) {
                    return string.split(',')
                } else {
                    return []
                }
            }
        }
    }
</script>