import {inDate} from "../functions";
import _ from "lodash";

import InvisionDatePicker from './fields/invisionDatePicker.vue'
import InvisionTimePicker from './fields/invisionTimePicker.vue'
import InvisionReadOnly from './fields/invisionReadOnly.vue'
import InvisionDayPicker from './fields/invisionDayPicker.vue'
import InvisionSelect from './fields/invisionSelect.vue'
import InvisionBoolean from './fields/invisionBoolean.vue'

const InvisionFieldDefaultSettings = {

    fields : {
        date : {
            default : inDate.dateToString(new Date()),
            config : {},
            component : InvisionDatePicker,
            componentName : 'InvisionDatePicker'
        },
        time : {
            default : '00:00:00',
            config : {},
            component : InvisionTimePicker,
            componentName : 'InvisionTimePicker'
        },
        datetime : {
            default : inDate.dateToString(new Date()),
            config : {},
            component : InvisionDatePicker,
            componentName : 'InvisionDatePicker'
        },
        days : {
            default : '',
            component: InvisionDayPicker,
            componentName : 'InvisionDayPicker'
        },
        readonly : {
            default : '',
            component: InvisionReadOnly,
            componentName : 'InvisionReadOnly'
        },
        select : {
            default : 0,
            component: InvisionSelect,
            componentName : 'InvisionSelect'
        },
        boolean : {
            default : 0,
            component : InvisionBoolean,
            componentName : 'InvisionBoolean'
        }
    },

    getDefaultFor (type) {
        return this.fields[type].default
    },

    getAllComponents ()
    {
        let components = {};

        _.each(this.fields, (field, key) => {
            components[field.componentName] = field.component;
        });

        return components;
    },

    getMap () {
        let map = {};

        _.each(this.fields, (field, key) => {
            map[key] = field.componentName;
        });

        return map;
    }
};

export {
    InvisionFieldDefaultSettings,
}