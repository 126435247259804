<template>
  <div class="editable-field">
    <span>
        <input
            @focus="editing = true"
            @focusout="editing = false"
            @blur="editing = false"
            ref="input"
            :value="value"
            @input="changeValue('input', $event, row, column)"
            @keydown.enter="editing=false"
            v-on:keydown.tab.prevent="changeCell('down', $event)"
            v-on:keydown.down.prevent="changeCell('down', $event)"
            v-on:keydown.up.prevent="changeCell('up', $event)"
            v-on:keydown.left.prevent="changeCell('left', $event)"
            v-on:keydown.right.prevent="changeCell('right', $event)"
            type="text"
            class="form-control"
        >
    </span>
  </div>
</template>
<script>
import { store } from '../store'
import { bus } from '../../bus'

export default {
  data() {
    return {
      editing: false,
    }
  },
  created() {
    bus.on('changedCell', this.changedCell);
    bus.on('updateRawData', this.updatedRaw);
  },
  destroyed() {
    bus.off('changedCell', this.changedCell);
    bus.off('updateRawData', this.updatedRaw);
  },
  props: ['tableIndex', 'value', 'row', 'column'],
  computed: {
    propData() {
      return {
        tableIndex: this.$props.tableIndex,
        value: this.$props.value,
        row: this.$props.row,
        column: this.$props.column,
      }
    }
  },
  methods: {
    changeValue(input, event, row, column) {
      let obj = {
        tableIndex: this.tableIndex,
        row,
        column,
        value: event.target.value,
      };
      if (row === -1) {
        store.commit('changeTitle', obj);
      } else {
        store.commit('changeValue', obj);
      }

      this.$emit(input, obj);
    },
    changeCell(direction, event) {
      let obj = {
        tableIndex: this.tableIndex,
        direction,
        row: this.$props.row,
        column: this.$props.column,
      };

      store.commit('editCell', obj);
      bus.$emit('changedCell', obj);
    },
    changedCell() {
      this.$data.editing = (store.state.editing.row === this.$props.row && store.state.editing.column === this.$props.column)
    },
    updatedRaw() {
      store.commit('updateRaw', this.propData)
    },
  }
}
</script>
