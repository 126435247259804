import {createStore} from 'vuex';
import axios from '../request';
import { bus } from '../bus';

const defaultTableData = () => ({
    maxRowIndex: 1,
    maxNameIndex: 2,
    data: [
        {
            "0": "Row A",
            "1": "a1",
            "2": "a2",
            "id": 0
        },
    ],
    fields: [
        {
            "name": "0",
            "title": "Top left corner",
            "id": 0
        },
        {
            "name": "1",
            "title": "First column header",
            "id": 1
        },
        {
            "name": "2",
            "title": "Second column header",
            "id": 2
        },
        {
            "name": "__slot:actions",
            "title": "Actions",
            "titleClass": "center aligned",
            "dataClass": "center aligned"
        }
    ],
    rawData: [
        [
            "Top left corner",
            "First column header",
            "Second column header",
        ],
        [
            "Row A",
            "a1",
            "a2",
        ],
    ],
    featuredRows: [],
});

export const store = createStore({
    state: {
        tableConfig: {
            backgroundColour: '#000000',
            textColour: '#ffffff',
        },
        toggles: {
            init: false,
            saving: false,
            error: false,
        },
        errorMessage: '',
        json: [defaultTableData()],
        editing: {
            row: -1,
            column: -1,
        },
    },
    mutations: {
        addColumn(state, {tableIndex}) {
            state.json[tableIndex].maxNameIndex++;
            let column = {
                name: state.json[tableIndex].maxNameIndex.toString(),
            };

            //Splice into array 1 before last, deleting 0 items (this is because the last column is our 'actions' column)
            state.json[tableIndex].fields.splice((state.json[tableIndex].fields.length - 1), 0, column);
        },
        changeValue(state, {tableIndex, column, row, value}) {
            let name = state.json[tableIndex].fields[column].name;
            state.json[tableIndex].data[row][name] = value;
        },
        changeTitle(state, {tableIndex, column, value}) {
            state.json[tableIndex].fields[column]['title'] = value;
        },
        editCell(state, {tableIndex, row, column, direction}) {
            switch (direction) {
                case 'down' :
                    if (row + 1 < state.json[tableIndex].data.length) row++;
                    break;
                case 'up':
                    if (row - 1 >= 0) row--;
                    break;
                case 'left':
                    if (column - 1 >= 0) column--;
                    break;
                case 'right' :
                    if (column + 1 < state.json[tableIndex].fields.length - 1) column++;
                    break;
            }
            state.editing.row = row;
            state.editing.column = column;
        },
        addRow(state, {tableIndex}) {
            state.json[tableIndex].maxRowIndex++;

            state.json[tableIndex].data.push({
                id: state.json[tableIndex].maxRowIndex,
            })
        },
        addTable(state) {
            state.json.push(defaultTableData());
        },
        removeTable(state, {tableIndex}) {
            state.json.splice(tableIndex, 1);
        },
        removeColumn(state, {tableIndex, columnIndex}) {
            //Remove the object from fields[]
            let field = state.json[tableIndex].fields.splice(columnIndex, 1)[0];

            //Remove any matching columns from all the objects in data[]
            for (let i = 0, len = state.json[tableIndex].data.length; i < len; i++) {
                delete state.json[tableIndex].data[i][field.name];
            }

        },
        removeRow(state, {tableIndex, rowIndex}) {
            state.json[tableIndex].data.splice(rowIndex, 1);
        },
        rowUp(state, {tableIndex, rowIndex}) {
            let row = state.json[tableIndex].data.splice(rowIndex, 1);
            state.json[tableIndex].data.splice(rowIndex - 1, 0, row[0])
        },
        rowDown(state, {tableIndex, rowIndex}) {
            let row = state.json[tableIndex].data.splice(rowIndex, 1);
            state.json[tableIndex].data.splice(rowIndex + 1, 0, row[0])
        },
        columnLeft(state, {tableIndex, columnIndex}) {
            let column = state.json[tableIndex].fields.splice(columnIndex, 1);
            state.json[tableIndex].fields.splice(columnIndex - 1, 0, column[0])
        },
        columnRight(state, {tableIndex, columnIndex}) {
            let column = state.json[tableIndex].fields.splice(columnIndex, 1);
            state.json[tableIndex].fields.splice(columnIndex + 1, 0, column[0])
        },
        loadTableData(state, data) {
            let json = null;
            try {
                json = (JSON.parse(data.timetable_json))
            } catch (error) {
                console.info('No JSON data found')
            }

            const loadIndividualTable = (tableIndex, table) => {
                state.json[tableIndex] = {
                    data: table.data,
                    fields: table.fields,
                };

                let copyArray = [
                    'maxNameIndex',
                    'maxRowIndex',
                    'rawData',
                    'featuredRows'
                ];

                for (let i = 0, len = copyArray.length; i < len; i++) {
                    state.json[tableIndex][copyArray[i]] = table[copyArray[i]];
                }
            };

            if (json instanceof Array) {
                json.map((tableData, i) => loadIndividualTable(i, tableData));
            } else if (json instanceof Object) {
                loadIndividualTable(0, json)
            }

            store.state.tableConfig.backgroundColour = data.background_colour;
            store.state.tableConfig.textColour = data.text_colour;

            state.toggles.init = true;

            return state;
        },
        saveTableData(state, url) {
            if (!state.toggles.saving) {

                //Clear rawData before updating
                state.json.forEach((tableData, i) => {
                    tableData.rawData.splice(0, state.json[i].data.length + 1);
                });

                bus.emit('updateRawData');
                bus.emit('updateRawFeaturedData');

                state.toggles.saving = true;
                axios.post(url, {
                    timetable_json: state.json
                })
                    .then(function (response) {
                        state.toggles.saving = false;
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            }

        },
        updateRaw(state, {tableIndex, row, column, value}) {

            if (typeof value === 'undefined') {
                value = '';
            }
            if (typeof state.json[tableIndex].rawData[row + 1] === 'undefined') {
                state.json[tableIndex].rawData[row + 1] = [];
            }
            state.json[tableIndex].rawData[row + 1][column] = value;
        },
        updateFeaturedRows(state, {tableIndex, rows}) {
            state.json[tableIndex].featuredRows = rows;
        },
        loadingError(state, error) {
            state.toggles.error = true;
        }

    }
});
