import {createStore} from 'vuex';
import axios from '../request';
import { inDate } from '../functions';

export const store = createStore({
    state: {
        toggles: {
            init: false,
            saving: false,
            error: false,
            selectingDateRange: false,
            savingDateRange: false,
            yearView: true,
            loaded: {
                events: false,
                schedules: false,
                linkables: false,
            },
            errors: {
                events: false,
                schedules: false,
                linkables: false,
            }
        },

        yearViewClickedStartDate: '',
        yearViewHoverDate: '',
        yearViewClickedEndDate: '',

        today: '',
        selected: '',
        events: [],
        schedules: [],
        dates: {},
        linkables: {},
        apiUrls: {
            update: '/admin/calendar/update'
        }
    },
    mutations: {
        setToday(state, today) {
            state.today = inDate.objToString(today);
            state.selected = state.today
        },
        setSelected(state, selected) {
            state.selected = inDate.objToString(selected)
        },

        loadDates(state, data) {
            for (let i = 0, len = data.length; i < len; i++) {

                let entry = data.formatFn(data[i]);
                state[data.type][data[i].id] = entry
                let dates = [];
                if (typeof data[i].dates !== 'undefined') {
                    dates = data[i].dates;
                } else {
                    dates = [{
                        date_from: data[i].date_from,
                        date_to: data[i].date_to,
                    }]
                }
                for (let j = 0, len = dates.length; j < len; j++) {

                    let from, to;

                    if (typeof data.dateFormat !== 'undefined') {
                        let dateF = data.dateFormat(dates[j]);

                        from = inDate.strToObj(dateF.date_from);
                        to = from;

                        if (dateF.date_to) to = inDate.strToObj(dateF.date_to);

                    } else {
                        from = inDate.strToObj(dates[j].date_from);
                        to = from;

                        if (dates[j].date_to) to = inDate.strToObj(dates[j].date_to);
                    }

                    if (to) {
                        let start = inDate.objToDate(from);
                        let end = inDate.objToDate(to);
                        while (start <= end) {
                            let dateString = inDate.dateToString(start);

                            if (typeof state.dates[dateString] === 'undefined') state.dates[dateString] = {};
                            if (typeof state.dates[dateString][data.type] === 'undefined') state.dates[dateString][data.type] = [];

                            state.dates[dateString][data.type].push(entry.id);
                            start.setDate(start.getDate() + 1);
                        }
                    }
                }
            }
            state.toggles.errors[data.type] = false;
            state.toggles.loaded[data.type] = true;
        },

        toggleYearView(state) {
            state.toggles.yearView = !state.toggles.yearView;
        },
        eventError(state) {
            state.toggles.errors.events = true;
        },
        scheduleError(state) {
            state.toggles.errors.schedules = true;
        },
        timetableError(state) {
            state.toggles.errors.linkables = true;
        },
        updateEventField(state, data) {
            state.events[data.id].featured = data.checked
        },

        datesSaving(state) {
            state.toggles.savingDateRange = true;
        },
        datesSaved(state) {
            state.toggles.savingDateRange = false;
        },

        setLinkable(state, object) {
            let start = state.yearViewClickedStartDate;
            let end = state.yearViewClickedEndDate;
            if (end < start) {
                start = [end, end = start][0]
            }
            while (start <= end) {
                let dateString = inDate.dateToString(start);

                if (typeof state.dates[dateString] === 'undefined') {
                    state.dates[dateString] = {};
                }

                if (typeof state.dates[dateString].linkables === 'undefined') {
                    state.dates[dateString]['linkables'] = [];
                }

                state.dates[dateString].linkables.splice(0, state.dates[dateString].linkables.length)
                state.dates[dateString].linkables.push(object.linkable)

                start.setDate(start.getDate() + 1);
            }
            state.yearViewClickedStartDate = '';
            state.yearViewHoverDate = '';
            state.yearViewClickedEndDate = '';

        },
        commitAction() {

        }

    },
    getters: {
        errorState: (state, getters) => (toggle) => {
            return state.toggles.loaded[toggle] ? 'text-success' : (store.state.toggles.errors[toggle] ? 'text-danger' : 'text-warning')
        },
        linkableColour: (state, getters) => (linkable) => {
            if (linkable) {
                if (linkable == 0) {
                    return 'white';
                } else {
                    return state.linkables[linkable].colour;
                }

            } else {
                return 'white'
            }
        },
        linkableTextColour: (state, getters) => (linkable) => {
            if (linkable) {
                if (linkable == 0) {
                    return 'black';
                } else {
                    return state.linkables[linkable].text_colour;
                }

            } else {
                return 'black'
            }
        },
        getDateAttachment: (state, getters) => (attachment, dateString) => {

            if (typeof state.dates[dateString] !== 'undefined') {
                if (
                    typeof state.dates[dateString][attachment] !== 'undefined' &&
                    state.dates[dateString][attachment].length > 0
                ) {
                    return state.dates[dateString][attachment]

                } else {
                    return null;
                }
            } else {
                return null;
            }
        }

    },
    actions: {
        updateEventField({commit, state}, event) {
            axios.post(state.apiUrls.update + '/event/updateField/' + event.id, {
                field: 'featured',
                value: event.checked ? 1 : 0
            }).then((response) => {
                console.log(response)
                commit('updateEventField', event);
            }).catch((error) => {
                console.warn(error)
            });
        },

        updateCalendarItemDates({commit, state}) {
            commit('datesSaving');

            let formattedDates = inDate.convertToCalendarDates(state.dates);

            axios.post(state.apiUrls.update + '/dates', {
                dates: formattedDates,
            }).then((response) => {
                console.log(response);
                commit('datesSaved')
            }).catch((error) => {
                console.warn(error)
            });
        },

        loadEventDates({commit, state}, data) {
            data.type = 'events';
            data.formatFn = (d) => {
                return ({
                    id: d.id,
                    title: d.title,
                    description: d.description,
                    featured: (d.featured === 1)
                })
            };
            commit('loadDates', data)
        },

        loadScheduleDates({commit, state}, data) {
            data.type = 'schedules';
            data.formatFn = (d) => {
                return ({
                    id: d.id,
                    title: d.title,
                    time: d.time,
                    allday: (d.all_day === 1),
                    description: d.description
                })
            };
            commit('loadDates', data)
        },

        loadCalendarItemDates({commit, state}, data) {
            data.type = 'linkables';
            data.formatFn = (d) => {
                if (d.hex_colour === '') {
                    if (typeof d.linked_item_object !== 'undefined') {
                        d.hex_colour = d.linked_item_object.background_colour;
                    }
                }
                return ({
                    id: d.id,
                    title: d.title,
                    colour: d.hex_colour,
                    text_colour: d.text_colour,
                    linked_item_object: d.linked_item_object

                })
            };
            data.dateFormat = (d) => {
                return ({
                    date_from: d.start_date,
                    date_to: d.end_date
                })
            };
            commit('loadDates', data)
        },

        clickedSquare({commit, state}, data) {
            if (state.toggles.selectingDateRange === false) {
                state.yearViewClickedStartDate = inDate.objToDate(data.date);
                state.yearViewHoverDate = inDate.objToDate(data.date);
                state.toggles.selectingDateRange = true;
                commit('commitAction', data)
            } else {
                state.yearViewClickedEndDate = inDate.objToDate(data.date);
                state.toggles.selectingDateRange = false;
                commit('setLinkable', data)
            }
        },

        hoveredSquare({commit, state}, data) {
            if (state.toggles.selectingDateRange === true) {
                state.yearViewHoverDate = inDate.objToDate(data.date);
                commit('commitAction', data)
            }

        }

    }
});