class VueAppController {
    constructor(vueInstance) {
        this.vueInstance = vueInstance;
    }

    // triggerSomeMethod(data) {
    //     if (this.vueInstance && this.vueInstance.someMethod) {
    //         this.vueInstance.someMethod(data);
    //     }
    // }

}

export default VueAppController;