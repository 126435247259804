<template>
    <thead>
        <tr>
            <th v-for="(title, index) in titles" v-bind:class="{ 'hidden' : (index == 0)}">
                {{ title }}
            </th>
        </tr>
    </thead>
</template>
<script>
    export default {
        name: 'list-titles',
        props: ['titles'],
        data(){
            return {

            }
        },
    }
</script>