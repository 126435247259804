<template>
  <BOffcanvas
    v-model="model"
    class="digi-booking"
    :placement="windowWidth < 992 ? 'bottom' : 'end'"
    :title="title"
    footer-class="footer"
    @close="close"
  >
    <template #header>
      <button
        v-if="navLevel > 0"
        class="btn-close back"
        @click="goBack"
      >
        <i class="fa-regular fa-arrow-left"></i>
      </button>
      <h5 class="offcanvas-title">
        {{ title }}
      </h5>
      <BCloseButton @click="close"/>
    </template>

    <BOverlay :show="initialising || loading.sessions || loading.events || loading.tickets">
      <div v-if="!selectedDate">
        <SessionCalendar
          :key="JSON.stringify(sessionDates)"
          :min-date="minDate || new Date()"
          :max-date="maxDate || new Date()"
          :custom-dates="customDates"
          :use-custom-dates="useCustomDates"
          :session-dates="sessionDates"
          :calendar-bg-colour="calendarBgColour"
          :calendar-text-colour="calendarTextColour"
          @day-click="selectDate"
        />

        <Products
          v-if="otherProducts.length"
          :products="otherProducts"
          :titleText="otherProductsText"
          :buttonBG="buttonBgColour"
          :buttonText="buttonTextColour"
          :buttonRadius="buttonRadius + 'px'"
          :buttonStyle="buttonTextClass"
        />
      </div>
      <template v-else-if="eventsForDate.length && !choosingSession">
        <h5 class="w-full mb-4 mt-2">{{ chooseYourEventText }}</h5>
        <EventCard
          v-for="(e, i) in eventsForDate"
          :key="`${e['eventCatID']}-${i}`"
          :event="e"
          :tickets="tickets"
          :buttonBG="buttonBgColour"
          :selected="e.eventCatID === selectedEvent?.eventCatID"
          :show-pricing="e.eventCatID === selectedEvent?.eventCatID && e.sessionType === 'Date-based'"
          @click="selectEvent(e)"
        />
      </template>
      <template v-else-if="choosingSession">
        <SessionPicker
          v-model="selectedDate"
          :event="selectedEvent"
          :min-date="minDate"
          :max-date="maxDate"
          :buttonBG="buttonBgColour"
          :buttonText="buttonTextColour"
          :low-capacity-threshold="props.lowCapacityThreshold"
          :tickets="tickets"
          @session-chosen="selectedSession = $event"
        />
      </template>
    </BOverlay>
    <template #footer>
      <div class="d-flex text-start flex-column footer position-relative">
        <div v-if="navLevel === 0"  class="text-center w-100 pb-3"><img src="../assets/powered-by-digitickets.svg" class="powered-by w-100" alt="Digitickets"/></div>
        <div v-if="navLevel !== 0" class="booking-date-button">
          <div
            v-if="choosingSession"
            class="row mx-0 justify-content-start align-items-center"
          >
            <div class="col confirmation pb-3 px-2">
              <i class="mx-2 fal fa-calendar-check"></i>
              {{ bookingDateText }}
            </div>
          </div>
          <BButton
              v-if="!selectedEvent"
              class="m-2 mb-0"
              block
              disabled
              :style="{background: '#ccc', color: '#fff', borderRadius: buttonRadius + 'px' }">
            CONTINUE <i class="fa-regular fa-arrow-right ms-2"></i>
          </BButton>
          <BButton
            v-if="selectedEvent"
            class="m-2 mb-0"
            :href="selectedSession ? bookingLink(selectedSession.sessionID, props.cfDomain, props.branchId) : null"
            target="_blank"
            :style="{background: buttonBgColour, color: buttonTextColour, borderRadius: buttonRadius + 'px' }"
            :class="buttonTextClass"
            block
            :disabled="!selectedSession && choosingSession"
            @click="selectedSession ? undefined : handleBookingClick()"
          >
            {{
              selectedEventRequiresTimePicker && !selectedSession && !choosingSession ? 'Select Time' : 'Book Now'
            }} <i class="fa-regular fa-arrow-right ms-2"></i>
          </BButton>
        </div>
      </div>
    </template>
  </BOffcanvas>
</template>

<script setup>
import {computed, onMounted, reactive, ref, watch} from "vue";
import moment from "moment";
import {bookingLink} from "../functions/linkFunctions";
import {sessionStartTime} from "../functions/sessionFunctions";
import SessionCalendar from "./SessionCalendar.vue";
import Products from "./Products.vue";
import EventCard from "./EventCard.vue";
import SessionPicker from "./SessionPicker.vue";
import {buildUri} from "../functions/urlFunctions";

const model = defineModel();

const props = defineProps({
  whenWouldYouLikeText: {
    type: String,
    required: true,
  },
  chooseYourEventText: {
    type: String,
    required: true,
  },
  otherProductsText: {
    type: String,
    required: true,
  },
  calendarBgColour: {
    type: String,
    required: true,
  },
  calendarTextColour: {
    type: String,
    required: true,
  },
  buttonBgColour: {
    type: String,
    required: true,
  },
  buttonTextColour: {
    type: String,
    required: true,
  },
  buttonRadius: {
    type: String,
    required: true,
  },
  buttonTextStyle: {
    type: String,
    required: true,
  },
  cfDomain: {
    type: String,
    required: true,
  },
  branchId: {
    type: Number,
    default: null,
    required: false,
  },
  lowCapacityThreshold: {
    type: Number,
    default: null,
    required: false,
  },
  otherProducts: {
    type: Array,
    default: null,
    required: false,
  },
  useCustomDates: {
    type: [Boolean, Number],
    default: false,
    required: false,
  },
  customDates: {
    type: Array,
    default: [],
    required: false,
  },
  eventIds: {
    type: Array,
    required: false,
  }
});

const selectedDate = ref(null);
const selectedEvent = ref(null);
const selectedSession = ref(null);
const navLevel = ref(0);
const choosingSession = ref(false);

const customEventIds = ref(null);
const initialising = ref(true);
const loading = reactive({
  events: false,
  sessions: false,
  tickets: false,
});

// API Data
const events = ref([]);
const tickets = ref([]);

onMounted(async () => {
  // Provide a mechanism to open the offcanvas from outside Vue
  if (window.vueAppController) {
    window.vueAppController.vueInstance = {openOffcanvas};
  }

  loading.sessions = true;
  loading.events = true;
  loading.tickets = true;

  // Fetch events, sessions & tickets
  await Promise.all([
    fetchEvents(),
    fetchTickets(),
  ]).then(async () => {
    await fetchSessions();
  });

  initialising.value = false;
});

watch(customEventIds, async () => {
  loading.sessions = true;
  loading.events = true;
  loading.tickets = true;

  await Promise.all([
    fetchEvents(),
    fetchTickets(),
  ]).then(async () => {
    await fetchSessions();
  })
});

// const title = computed(() => (selectedDate.value ? moment(selectedDate.value).format('Do MMMM YYYY') : props.whenWouldYouLikeText));

const buttonTextClass = computed(() => {
  if (props.buttonTextStyle === "1") {
    return "text-uppercase";
  } else if (props.buttonTextStyle === "2") {
    return "text-capitalize";
  }
  return ""; // Default case (no specific style)
});

const title = computed(() => {
  // console.log('Selected Event:', selectedEvent.value);
  // console.log('Selected Session:', selectedSession.value);
  if (selectedEvent.value) {
    return selectedEvent.value.name.substring(0, 50);

  }
  return selectedDate.value
      ? moment(selectedDate.value).format('Do MMMM YYYY')
      : props.whenWouldYouLikeText;
});

const selectedEventRequiresTimePicker = computed(() => selectedEvent.value.sessionType === 'Time-based' || selectedEvent.value.sessions.length > 1)
const windowWidth = computed(() => window.innerWidth)

const eventsForDate = computed(() => {
  const eventsWithSessionsForDate = events.value.map(
    (e) => ({
      ...e,
      allSessions: e.sessions,
      sessions: e.sessions.filter(
        (s) => moment(new Date(s['date'])).isSame(moment(selectedDate.value), 'date')
      ),
    })
  );

  return eventsWithSessionsForDate.filter((e) => e.sessions.length && e.sessions.some((s) => s.spacesAvailable > 0)).sort((a, b) => a.navOrder - b.navOrder);
});

const sessionDates = computed(() => Array.from(
  new Set(
    events
      .value
      .map((e) => e.sessions.map((s) => s['date']))
      .flat(Infinity)
      .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
  )).map((d) => moment(d).toDate())
);

const minDate = computed(() => sessionDates.value.at(0));
const maxDate = computed(() => sessionDates.value.at(-1));

const bookingDateText = computed(() => {
  if (!selectedDate.value) {
    return '';
  }

  const formattedDate = moment(selectedDate.value).format('Do MMMM YYYY');

  return selectedSession.value
    ? formattedDate + ' @ ' + moment(sessionStartTime(selectedSession.value)).format('HH:mm')
    : formattedDate
})

////////////////////////////
// Data fetch functions
////////////////////////////
const fetchEvents = async () => {
  await fetch(
    buildUri('/api/digitickets/events', {
      branchId: props.branchId,
      eventIds: customEventIds.value || props.eventIds,
    })
  )
    .then((res) => res.json())
    .then((e) => {
      loading.events = false;
      events.value = e.map((e) => ({
        ...e,
        sessions: [],
      }))
    });
}

const fetchSessions = async () => {
  await fetch(
    buildUri('/api/digitickets/sessions', {
      eventIds: customEventIds.value || props.eventIds,
      branchId: props.branchId,
    })
  )
    .then((res) => res.json())
    .then((sessions) => {
      loading.sessions = false;
      sessions.forEach((s) => {
        events.value.find(
          (e) => e.eventCatID === s.eventCatID
        )?.sessions.push(s);
      })
    })
}

const fetchTickets = async () => {
  await fetch(
    buildUri('/api/digitickets/tickets', {
      eventIds: customEventIds.value || props.eventIds,
      branchId: props.branchId,
    })
  )
    .then((res) => res.json())
    .then((t) => {
      loading.tickets = false;
      tickets.value = t;
    })
}

////////////////////////////
// Helper functions
////////////////////////////

/**
 * Nav levels:
 *  0: Starting level, only the starting calendar is shown with all available sessions
 *  1: A date has been selected. A date-based event may also have been chosen
 *  2: A time-based event has been selected and the user is choosing a session time
 */
const goBack = () => {
  if (navLevel.value === 1) {
    selectedDate.value = null;
    selectedEvent.value = null;
    navLevel.value--;

    return;
  }

  if (navLevel.value === 2) {
    choosingSession.value = false
    selectedSession.value = null;
    navLevel.value--;
  }
}

const handleBookingClick = () => {
  if (!selectedEventRequiresTimePicker) {
    return;
  }
  choosingSession.value = true;
  navLevel.value = 2;
}

const selectDate = (cell) => {
  if (!sessionDates.value.find((d) => moment(d).isSame(moment(cell.date), 'date'))) {
    return;
  }

  selectedDate.value = cell.date;
  navLevel.value = 1;
}

const selectEvent = (event) => {
  selectedEvent.value = event;

  // If the selected session is time based or has multiple sessions for the date
  // then we won't set the selected session just yet. Otherwise, set the selected
  // session as the only one available for this date.
  if (event.sessionType === 'Time-based' || event.sessions.length > 1) {
    selectedSession.value = null;

    return;
  }

  selectedSession.value = event.sessions[0];
}

const close = () => {
  model.value = false;
  selectedDate.value = null;
  selectedEvent.value = null;
  selectedSession.value = null;
  choosingSession.value = false;
  navLevel.value = 0;
  customEventIds.value = null;
}

const openOffcanvas = (eventIds = null) => {
  customEventIds.value = eventIds;
  model.value = true;
}

</script>

<style scoped lang="scss">
.offcanvas-header {
  position: relative;

  .btn-close {
    position: absolute;
    padding: 15px;
    --bs-btn-close-opacity: 1;
  }

  .btn-close:not(.back) {
    right: 15px;
    background: url("../assets/icons/circle-xmark-light.svg") no-repeat center center;
    background-size: 30px 30px;
  }
}
.back {
  --bs-btn-close-bg: none;
  left: 5px;

  i {
    top: -4px;
    position: relative;
    font-size: 22px; color: #a5a5a5
  }
}

.powered-by {
  height: 20px;   margin-top: 20px;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.footer {
  box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.08);
  //padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);


  .booking-date-button {
    box-shadow: 0px -8px 20px #00000014;
    padding: 16px;
    position: absolute;
    bottom: 100%;
    background: #fff;
    font-size: 1.125rem;
    left: 0;
    width: 100%;

    .confirmation {
      font-weight: bold;
    }

    .btn.btn-secondary {
      width: 100%;
      margin: 0 !important;
      border-radius: 0;
      padding: 13px 20px;
      background: #000;
      font-weight: bold;
      &:hover {
        background: #DADADA; color: #fff !important;
      }
      &.disabled {
        background: #ccc !important; color: #fff !important;
      }
    }
  }
}
</style>

<style lang="scss">

.offcanvas.digi-booking {
  &.offcanvas-bottom {
    height: 95%;
    border-radius: 16px 16px 0 0;
  }

  &.offcanvas-end {
    width: 500px;
  }

  .offcanvas-header {
    border-bottom: 1px solid #D3D3D3;
  }

  .offcanvas-body {
    padding-bottom: 90px ;
  }

  color: #000;
  text-align: center;
  font-family: 'Arial', Sans-Serif, serif;
  font-size: 16px;
  font-style: normal;
  line-height: 147%;

  h5 {
    font-weight: 700;
    font-size: 16px;
  }

  .btn { border-radius: 0;
    &.btn-default {
      padding: 15px 20px;
      font-weight: bold;
      &:hover {
        background: var(--button-hover) !important; color: var(--button-hover-color) !important;
      }
    }
  }
  .border-secondary {
    border: 1px solid #d9d9d9 !important;
    &.border-2 { border-width: 2px !important;}
  }
}

</style>
