import _ from 'lodash';

//Function library for inVision Vue components

//Date obj/string manipulation {year, month, day} -> (YYYY-mm-dd)
const inDate = {

    lang : {
        en : {
            days : ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            letterDays : ['S','M','T','W','T','F','S'],
            months : ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        }
    },
    /**
     * Return the number with English ordinal
     * @param i {int}
     * @returns {string}
     */
    ordinal_suffix_of(i) {
        let j = i % 10,
            k = i % 100;
        if (j === 1 && k !== 11) {
            return i + "st";
        }
        if (j === 2 && k !== 12) {
            return i + "nd";
        }
        if (j === 3 && k !== 13) {
            return i + "rd";
        }
        return i + "th";
    },
    /**
     * Basic Calendar Generation in week rows
     * @param year
     * @param month
     * @returns {Array}
     */
    getDaysInMonth(year, month) {
        let date = new Date(year, month - 1, 1);
        let result = [];
        let rowCount = 0;
        while (date.getMonth() === month - 1) {
            if (typeof result[rowCount] == 'undefined') {
                result[rowCount] = [];
            }
            result[rowCount][date.getDay()] = date.getDate();
            if (date.getDay() === 6) {
                rowCount ++
            }
            date.setDate(date.getDate() + 1);
        }

        return result;
    },
    /**
     * Compares a date object with a formatted string
     * @param obj {{}}
     * @param string {string}
     * @returns {boolean}
     */
    compare(obj, string){
        return(this.objToString(obj) === string)
    },
    /**
     * Converts a date object to a formatted string
     * @param obj {{}}
     * @returns {string}
     */
    objToString(obj) {
        return obj.year + '-' + ('0' + obj.month).slice(-2) + '-' + ('0' + obj.day).slice(-2)
    },
    /**
     *
     * @param obj
     * @returns {Date}
     */
    objToDate(obj) {
        return new Date(obj.year, obj.month - 1, obj.day);

    },
    /**
     *
     * @param date
     * @returns {{year: (number|*), month: *, day: (number|*)}}
     */
    dateToObj(date) {
        return {
            year : date.getFullYear(),
            month : date.getMonth() + 1,
            day : date.getDate()
        }
    },
    /**
     *  Converts a date to a string
     * @param date {Date}
     * @returns {string}
     */
    dateToString(date) {
        return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
    },
    /**
     *  Converts a formatted string to date object
     * @param str {string}
     * @returns {*}
     */
    strToObj(str) {
        if (str.length < 10) return false;
        let year = str.slice(0,4), month = str.slice(5,7), day = str.slice(8,10);
        return {
            year,
            month,
            day
        }
    },
    strToFriendly(str, lang) {
        let obj = this.stringToDate(str);
        return this.ordinal_suffix_of(obj.getDate()) + ' ' +
            this.lang[lang].months[obj.getMonth()] + ' ' +
            obj.getFullYear()
    },
    extractTime(str) {

    },
    /**
     *
     * @param str {string} H:i
     */
    timeToSeconds(str) {
        return (str.slice(0,2) * 3600) + (str.slice(3,5) * 60);
    },
    /**
     *  Converts a string back into a Date
     * @param str
     * @returns {Date}
     */
    stringToDate(str) {
        let obj = this.strToObj(str);
        return this.objToDate(obj);
    },

    /**
     *
     * @param next
     * @param first
     * @returns {boolean}
     */
    isNext(first, next){
        next = this.stringToDate(next);
        first = this.stringToDate(first);

        return ((first.getDate() + 1) === next.getDate() )
    },

    /**
     *
     * @param dateArray
     */
    convertToCalendarDates(dateArray)
    {
        let lib = this;

        let filteredArray = [], calendarDates = [], lastDate, lastLinkable, lastIndex = -1;

        //filter out any dates with no linkable calendar items
        _.each(dateArray, function(date, key){
            if (typeof date.linkables !== 'undefined') {
                filteredArray.push({ date : key, obj : date })
            }
        });

        //sort filtered array into date order
        filteredArray = _.sortBy(filteredArray, [function(o) {
           return lib.stringToDate(o.date);
        }]);

        //iterate through filtered array, setting up calendar date items for consecutive dates of same item
        _.each(filteredArray, function(item) {

            let usingSameObject = false;

            if (typeof calendarDates[lastIndex] !== 'undefined')
            {
                let last = calendarDates[lastIndex];

                if (last.calendar_item_id === _.first(item.obj.linkables)) {
                    if (lib.isNext(last.end_date, item.date)) {
                        calendarDates[lastIndex].end_date = item.date;
                        usingSameObject = true;
                    }
                }
            }
            if (!usingSameObject){
                if (_.first(item.obj.linkables) > 0) {
                    calendarDates.push(
                        {
                            calendar_item_id : _.first(item.obj.linkables),
                            start_date : item.date,
                            end_date : item.date
                        }
                    );
                    lastIndex ++;
                }
            }

            lastDate = item.date;
            lastLinkable = _.first(item.obj.linkables)

        });

        return calendarDates;
    }

};

export {inDate}