<template>
    <div>
        <div id="controlBarWrap">
            <div class="panel panel-default p-1" id="controlBar" v-show="toggles.init">
                <div class="row">
                    <div class="col-auto px-2">
                        <span class="btn btn-default input-group-btn" v-on:click="addRow()">Add Row</span>
                    </div>
                    <div class="col-auto px-2">
                        <span class="btn btn-success" v-on:click="saveData()" id="invisionEditableListingSave" v-bind:class="{ saving : toggles.saving}">
                    <span v-if="toggles.saving">
                        <i class="fa fa-spinner fa-spin"></i>
                    </span>
                            <span v-else>
                        Save Data
                    </span>
                        </span>
                    </div>

                </div>
            </div>
        </div>
        <div v-if="!toggles.init">
            <p v-if="!toggles.error"><i class="fa fa-spin fa-spinner fa-2x"></i> Loading data ...</p>
            <p v-else>An error occurred while attempting to load your data, try refreshing the page </p>
        </div>
        <div class="panel panel-default" v-else style="position: relative">

            <div class="table-responsive">
                <table class="table table-sm">
                    <thead is="listTitles" :titles="dateRangeTitles">
                    </thead>
                    <tbody is="transition-group" name="fade">
                    <tr is="list-row"
                        class="listing-row"
                        v-for="(row, index) in rowData"
                        :index="index"
                        :row-data="row"
                        :row-settings="rowSettings"
                        v-on:delete-row="deleteRow($event)"
                        v-on:row-updated="updateRow($event, rowSettings)"
                        v-bind:key="'listing-row' + row.id"
                    ></tr>
                    </tbody>
                </table>
            </div>
            <div v-if="toggles.saving" style="position: absolute; top: 0; right: 0; width:100%; height: 100%; background: rgba(0,0,0,0.2)"></div>
        </div>

        </div>

</template>

<script>

    import { store } from '../store'
    import axios from '../../request'
    import listRow from './listRow.vue'
    import listTitles from './listTitles.vue'

    import _ from 'lodash'

    export default {

        props : ['mode', 'settings', 'api', 'with'],
        data() {
            return {

            }
        },
        mounted() {
            store.commit('importSettings', JSON.parse(this.$props.settings));
            store.commit('setApi', this.$props.api);
            store.commit('setWith', this.$props.with);
        },
        created(){

            axios.get(this.$props.api + '/get?with=' + this.$props.with)
                .then( (response) => {
                    store.dispatch('loadListings', response.data);

                })
                .catch( function(error) {

                    console.log(error)
                });
        },
        components: {
            listRow,
            listTitles
        },
        computed : {

            rowData () {
                if (typeof store.state.entries[0] !== 'undefined') {
                    return store.state.entries[0][this.$props.with]
                } else {
                    return {}
                }

            },
            rowSettings () {
                if (typeof store.state.settings.related[this.$props.with] !== 'undefined') {
                    return store.state.settings.related[this.$props.with]
                } else {
                    return ['']
                }
            },
            toggles () {
                return store.state.toggles
            },
            dateRangeTitles () {
                if (typeof store.state.settings.related[this.$props.with] !== 'undefined') {
                    return store.state.settings.related[this.$props.with].map(obj => obj.label)
                } else {
                    return ['titles']
                }

            }

        },
        methods : {
            addRow (){
                store.dispatch('addRow', { type: this.$props.with} )
            },
            deleteRow (index){
                store.dispatch('deleteRow', { type: this.$props.with, index})
            },
            saveData (){
                store.dispatch('saveData')
            },
            updateRow (data, settings){
                let valuesObj = {};

                _.each(settings, (setting, index) => {
                    let fieldString = setting.field;
                    valuesObj[fieldString] = data[index];
                });

                store.dispatch('updateRow', {type: this.$props.with, values: valuesObj})
            }
        }
    }
</script>