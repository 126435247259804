// Third Party
import {createBootstrap} from 'bootstrap-vue-next';
import VCalendar from 'v-calendar';

import {createApp} from 'vue';
import invisiontable from './invisiontable/components/table.vue';
import invisioncalendar from './invisioncalendar/components/calendar.vue'
import invisionEditableListing from './invisionEditableListing/components/listing.vue'
import BookingWidget from './dtbookingwidget/components/BookingWidget.vue'
import BookNow from './dtbookingwidget/components/BookNow.vue'
import VueAppController from './VueAppController';

import 'v-calendar/style.css';

const app = createApp({
    components: {
        invisiontable,
        invisioncalendar,
        invisionEditableListing,
        BookingWidget,
        BookNow,
    }
});

const vueAppController = new VueAppController(app);

// Expose the controller globally
window.vueAppController = vueAppController;

app.use(createBootstrap({components: true, directives: true}));
app.use(VCalendar, {});

app.component('invision-editable-listing', invisionEditableListing)
    .component('invisioncalendar', invisioncalendar)
    .component('invisiontable', invisiontable)
    .component('booking-widget', BookingWidget)
    .component('book-now', BookNow);

app.mount('#vueModule');
