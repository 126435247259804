<template>

    <div v-if="selected">
        <span v-if="scheduleData.allday" class="badge badge-warning">All Day </span>
        <span class="badge badge-warning" v-else>{{ scheduleData.time }} </span>
        - {{scheduleData.title}}
    </div>

    <span v-else-if="scheduleData">
         <span class="badge badge-warning badge-xs mr-1" v-if="index < 3">
        {{ scheduleData.title }}
    </span>
        <span class="badge badge-warning badge-xs mr-1" v-else-if="index == 4">
        ...
    </span>
    </span>
</template>
<script>
    import { store } from '../store'

    export default {
        props : ['schedule', 'mode', 'index'],
        computed : {
            scheduleData() {
                return store.state.schedules[this.$props.schedule]
            },
            selected() {
                return (this.$props.mode === 'selected')
            }
        }
    }
</script>