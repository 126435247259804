<template>
  <div>
    <div id="controlBarWrap">
      <div class="panel panel-default p-2" id="controlBar" v-show="toggles.loaded.events">
        <div class="row">
          <div class="col-12 col-md-auto px-3">
            <div class="input-group input-group-sm">
              <button class="btn btn-outline-primary input-group-btn" @click="removeYear"><i class="fa fa-angle-left"></i></button>
              <input v-model="year" class="form-control text-center" />
              <button class="btn btn-outline-primary input-group-btn" @click="addYear"><i class="fa fa-angle-right"></i></button>
            </div>
          </div>
          <div class="col-12 col-md-auto px-3" v-if="!toggles.yearView">
            <div class="input-group input-group-sm">
              <button class="btn btn-outline-primary input-group-btn" @click="removeMonth"><i class="fa fa-angle-left"></i></button>
              <input v-model="monthName" class="form-control text-center" />
              <button class="btn btn-outline-primary input-group-btn" @click="addMonth"><i class="fa fa-angle-right"></i></button>
            </div>
          </div>
          <div class="col-12 col-md px-3 mt-3 mt-md-0" v-if="toggles.yearView">
            <div class="input-group">
                            <span class="input-group-addon">
                                Calendar items
                            </span>
              <select class="form-control custom-select" v-model="selectedLinkable" :style="linkableOption(selectedLinkable, true)">
                <option value="0">Clear</option>
                <option v-for="linkable in linkables" :value="linkable.id" :style="linkableOption(linkable)"> {{ linkable.title }} </option>
              </select>
            </div>


          </div>
          <div class="col d-none d-xl-block">

          </div>
<!--          <div class="col-auto px-2">-->
<!--            <button class="btn btn-outline-primary" v-on:click="toggleYearView">View Mode</button>-->
<!--          </div>-->
          <div class="col-12 col-md-auto px-3 text-center text-md-end mt-3 mt-md-0">
            <a class="btn btn-success" href="#" v-on:click="saveCalendarDates">
              <span v-if="!toggles.savingDateRange">Save Calendar</span>
              <span v-else class="px-3"><i class="fa fa-spin fa-spinner"></i></span>
            </a>
          </div>
          <!--<div class="col-auto px-2">-->
          <!--<button class="btn btn-info"><i class="fa fa-cog"></i> &nbsp;Settings</button>-->
          <!--</div>-->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <template v-if="!toggles.yearView">
          <div class="table-responsive" style="min-height: 900px">
            <table ref="calendar" class="table table-sm">
              <thead>
              <tr class="bg-secondary text-alt">
                <th v-for="day in lang[currentLang].days" class="text-center">
                  {{day}}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="week in dates">
                <td v-for="day in week">
                  <daycard
                      :day="day"
                      :month="month"
                      :year="year"
                      :table="table"
                  ></daycard>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </template>
        <template v-else>
          <div class="row">
            <div class="col-12 col-md-4" v-for="month, n in yearCalc">
              <div class="admin-cal">
                <div class="text-primary text-center p-2"><span class="d-block bg-med-grey py-2">{{ lang[currentLang].months[n] }} {{ year }}</span></div>
                <table ref="calendar" class="table table-sm">
                  <thead>
                  <tr>
                    <th v-for="d in lang[currentLang].letterDays"> {{ d }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="week in month">
                    <td v-for="day in week">
                      <day-square :day="day" :month="n+1" :year="year" :selected-linkable="selectedLinkable"></day-square>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </template>
      </div>
      <!-- Never really understood why this is being displayed? -->
      <!--            <div class="col-2" >-->
      <!--                <div class="panel panel-default">-->
      <!--                    <h6 class="bg-secondary text-alt p-1 my-0">Imported Modules</h6>-->
      <!--                    <div class="p-1">-->
      <!--                        Events <i class="fa fa-circle" v-bind:class="store.getters.errorState('events')"></i>-->
      <!--                        <i v-if="toggles.errors.events" class="fa fa-undo" @click="loadEvents"></i>-->
      <!--                    </div>-->
      <!--                    <div class="p-1">-->
      <!--                        Schedule <i class="fa fa-circle" v-bind:class="store.getters.errorState('schedules')"></i>-->
      <!--                        <i v-if="toggles.errors.schedules" class="fa fa-undo" @click="loadSchedule"></i>-->
      <!--                    </div>-->
      <!--                    <div class="p-1">-->
      <!--                        Calendar Items <i class="fa fa-circle" v-bind:class="store.getters.errorState('linkables')"></i>-->
      <!--                        <i v-if="toggles.errors.linkables" class="fa fa-undo" @click="loadLinkables"></i>-->
      <!--                    </div>-->
      <!--                </div>-->
      <!--                <selected-panel v-if="!toggles.yearView && selected" :selected="selected"></selected-panel>-->
      <!--            </div>-->
    </div>

  </div>
</template>
<script>
import { store } from '../store'
import axios from '../../request'
import daycard from './daycard.vue'
import daySquare from './daySquare.vue'
import selectedPanel from './selectedPanel.vue'
import _ from 'lodash'
import {inDate} from '../../functions'


export default {
  mounted () {
    console.log('Calendar Mounted');
    this.setToday();
    this.loadEvents();
    this.loadSchedule();
    this.loadLinkables();
  },
  props : [
    'eventsApi',
    'scheduleApi',
    'timetableApi',
    'offersApi',
    'updateApi',
    'currentMonth',
    'currentDay',
    'currentYear'
  ],
  data () {
    return {
      currentLang : 'en',
      month : 4,
      year : 2016,
      table : 0,
      lang : inDate.lang,
      store,
      selectedLinkable : 0
    }
  },
  computed : {
    yearCalc() {
      let year = [];
      for (let i =1; i < 13; i++) {
        year.push(this.calcDates(parseInt(this.year),i ))
      }
      return year
    },
    dates() {
      return this.calcDates(parseInt(this.year), parseInt(this.month))
    },
    monthName() {
      return (new Date(this.year, this.month - 1, 1)).toLocaleString('default', { month: 'long' });
    },
    today() {
      return {
        year : this.$props.currentYear,
        month : this.$props.currentMonth,
        day : this.$props.currentDay,
      }
    },
    selected () {
      return store.state.selected
    },
    toggles(){
      return store.state.toggles
    },
    linkables () {
      return store.state.linkables
    },
    selectedEvents () {
      if (typeof store.state.dates[this.selected] !== 'undefined') {
        return store.state.dates[this.selected].events
      } else {
        return null;
      }
    },
    selectedTables () {
      if (typeof store.state.dates[this.selected] !== 'undefined') {
        return store.state.dates[this.selected].tables
      } else {
        return null;
      }
    },


  },
  methods : {
    calcDates(year, month) {
      return inDate.getDaysInMonth(year, month);
    },
    setToday() {
      this.month = this.today.month;
      this.year = this.today.year;
      store.commit('setToday', this.today)
    },
    loadEvents() {
      axios.get(this.$props.eventsApi)
          .then( (response)=> {
            console.log(response);
            store.dispatch('loadEventDates', response.data)
          }).catch( (error)=> {
        store.commit('eventError')
        console.warn(error)
      })
    },
    loadSchedule() {
      axios.get(this.$props.scheduleApi)
          .then( (response)=> {
            console.log(response);
            store.dispatch('loadScheduleDates', response.data)
          }).catch( (error)=> {
        store.commit('scheduleError')
        console.warn(error)
      })
    },
    loadOffers() {

    },
    linkableOption(linkable, isInt = false) {
      let backgroundColor = 'white', color = 'black';
      if (isInt) {
        backgroundColor =  store.getters.linkableColour(linkable);
        color = store.getters.linkableTextColour(linkable)
      } else {
        backgroundColor =  linkable.colour
        color = 'white'
      }
      return {
        backgroundColor,
        color
      }
    },
    loadLinkables() {
      axios.get(this.$props.timetableApi)
          .then( (response)=> {
            console.log(response);
            store.dispatch('loadCalendarItemDates', response.data)
          }).catch( (error)=> {
        store.commit('timetableError');
        console.warn(error)
      })
    },
    saveCalendarDates(){
      store.dispatch('updateCalendarItemDates')
    },
    toggleYearView(){
      store.commit('toggleYearView')
    },
    addMonth() {
      this.$data.month++;
    },
    removeMonth() {
      this.$data.month--;
    },
    addYear() {
      this.$data.year++;
    },
    removeYear(){
      this.$data.year--;
    }

  },
  components : {
    daycard,
    selectedPanel,
    daySquare
  }
}
</script>
