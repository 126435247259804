<template>
  <div v-if="ticketsForEvent.length">
  <p class="fw-bold mb-0 text-start">Ticket Options</p>
  <div class="d-flex flex-column mt-2">
    <div
        v-for="(t, i) in ticketsForEvent"
        :key="i"
        class="d-flex justify-content-between align-items-center"
    >
      <p>{{ t.name }}</p>
      <p class="text-success">{{ formatTicketPrice(t.price) }}</p>
    </div>
  </div>
</div>
</template>

<script setup>
import {computed} from "vue";
import {formatCurrency} from "../functions/currencyFunctions";

const props = defineProps({
  event: {
    type: Object,
    required: true,
  },
  tickets: {
    type: Array,
    required: true,
  }
});

const ticketsForEvent = computed(() => props.tickets.filter((t) => t.eventCatID === props.event.eventCatID));

const formatTicketPrice = (price) => price === 0 ? 'Free' : `${formatCurrency(price)}`
</script>

<style scoped lang="scss">

</style>